import logo from './logo.png';
import './App.css';

function Privacy({name}) {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Logo" />
        <h1> App User Privacy Policy Notice </h1>
        <h1> {name} </h1>
        <h2> Data that we hold and how we use it </h2>
        <p>As an app user, we do not act as the Controller for the messages between you and the Provider {name}.
          We are the Controller of your registration data for the App itself, any log data and then your Nivo Identity if you have set one up.
          Your Nivo identity will include the results of your ID&V check, if you have agreed to share it with us for future use with a new provider.
          When you attempt to start a conversation with a provider on Nivo, you will be asked to consent to share your Nivo identity information.
          If you choose to consent that identity will be shared with the provider.</p>
        <h2>Lawful basis for processing</h2>
        <p>Our lawful basis for processing your data is a combination of Contract, Consent and Legitimate Interest.
          We use Contract as a lawful basis for the data you provided when you downloaded and registered yourself as a user on the App.
          If you create a Nivo ID and add the results of an ID&V check, we ask your consent to transfer that from your provider to us.
          Once transferred, we process it under contract and legitimate interest, depending on the process.
          We use consent if we process any of the biometric data from your ID&V check.</p>
        <h2>Data Sharing and Transfers</h2>
        <p>Like most companies, we use a number of other companies as part of our data processing, for example cloud services and technology services.
          We have Data Processing Agreements in place with these providers.
          Where data is transferred outside of the EEA, we ensure that appropriate protection and mechanisms are in place, for example Standard Contractual Clauses.
          We do not sell your data to anybody.
          As part of the service we offer you, with your consent, and only upon your instruction, we transfer your Nivo Identity to new providers.
        </p>
        <h2>Retention Periods</h2>
        <p>We hold data on our app users for the time of which you are an active user and then for 7 years afterwards in case of any dispute.
          This is to ensure that our customers continue to have an auditable record of the identity information which may be required for them under the laws and regulations they operate.
          It will also ensure that you can continue to easily communicate and transact with other providers on the Nivo network.</p>
      </header>
    </div>

  );
}

export default Privacy;

import './App.css';
import { Route, Routes } from "react-router-dom"
import Privacy from './privacy';

function App() {
  return (
      <Routes>
        <Route path="/" element={<Privacy name="Nivo" />} />
        <Route path="/ace" element={<Privacy name="Ace Solutions" />} />
        <Route path="/autarky" element={<Privacy name="Autarky" />} />
        <Route path="/cls" element={<Privacy name="CLS Money" />} />
        <Route path="/coop" element={<Privacy name="The Co-operative Bank Business Help" />} />
        <Route path="/crystal" element={<Privacy name="Crystal Specialist Finance" />} />
        <Route path="/evolution" element={<Privacy name="Evolution Money" />} />
        <Route path="/loanable" element={<Privacy name="Loanable" />} />
        <Route path="/love" element={<Privacy name="Love Finance" />} />
        <Route path="/mfs" element={<Privacy name="Market Financial Solutions" />} />
        <Route path="/me" element={<Privacy name="Mortgage Experience" />} />
        <Route path="/mortgagehut" element={<Privacy name="Mortgage Hut" />} />
        <Route path="/ocean" element={<Privacy name="Ocean" />} />
        <Route path="/pepper" element={<Privacy name="Pepper" />} />
        <Route path="/sbs" element={<Privacy name="Scottish Building Society" />} />
        <Route path="/selina" element={<Privacy name="Selina Finance" />} />
        <Route path="/sercle" element={<Privacy name="Sercle" />} />
        <Route path="/stepone" element={<Privacy name="Step One" />} />
        <Route path="/time" element={<Privacy name="Time Finance" />} />
        <Route path="/together" element={<Privacy name="Together Money" />} />
        <Route path="/utb" element={<Privacy name="United Trust Bank" />} />
            <Route path="/atl" element={<Privacy name="ATL Global" />} />
      </Routes>
  );
}

export default App;
